
import React from "react";
import './Foto.css'



function Foto(){
    return (
        <h1>still under construction</h1>
    )
}

export default Foto